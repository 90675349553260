.AreaHighlight {
    border: 1px solid #333;
    background-color: rgb(108, 106, 100);
    opacity: 1;
    mix-blend-mode: multiply;
}

.AreaHighlight__part {
    cursor: pointer;
    position: absolute;
    background: rgb(143, 240, 255);
    transition: background 0.3s;
}

.Highlight__parts {
    opacity: 1;
}

.Highlight__part {
    /* cursor: pointer; */
    position: absolute;
    background: rgba(255, 255, 255, 0) !important;
    transition: background 0.3s;
}
.Highlight--scrolledTo .Highlight__part {
    background: rgba(255, 226, 143, 1) !important;
}



.AreaHighlight--scrolledTo .AreaHighlight__part {
    /* background: #ff4141; */
}

.my-pdf-viewer {
    background: rgb(113, 144, 96)
}